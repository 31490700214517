<template>
  <div style="position: relative; height: 100%">
    <!-- 顶部导航条 -->
    <header v-if="isAirRoute">
      <ul class="tabs">
        <li class="active" @click="changeTab(0)">航线</li>
        <li style="margin: 0 68px" @click="changeTab(1)">影像数据</li>
        <!-- <li @click="changeTab(2)">视频</li> -->
      </ul>
    </header>
    <div id="project" v-show="tab === 0">
      <!--高德地图容器-->
      <div class="centerMap" id="centerMap" v-show="!Shou3D"></div>
      <!--3D地图容器-->
      <div class="center3DMap" id="center3DMap" v-show="Shou3D"></div>
      <!--高德操作图标-->
      <div class="mapControl" v-show="!Shou3D">
        <div class="item" @click="searchShowFn" ref="searchBar">
          <img src="../../assets/img/Icon/search.png" alt />
        </div>
        <div class="item" ref="measureBar">
          <img src="../../assets/img/Icon/measure.png" @click="measureFn" alt />
          <div class="measureBox" v-if="measureShow">
            <div ref="stadiometry" @click="stadiometryFn">
              <span>测距</span>
            </div>
            <div ref="mSurface" @click="mSurfaceFn">
              <span>测面</span>
            </div>
          </div>
        </div>
        <div class="item" ref="hierarchyBar">
          <img
            src="../../assets/img/Icon/hierarchy.png"
            @click="hierarchyFn"
            alt
          />
          <div class="hierarchyBox" v-if="hierarchyShou">
            <div class="clearfix">
              <label>
                <input type="radio" v-model="mapStyle1" value="平面" />
                <span>平面</span>
              </label>
            </div>
            <div class="clearfix">
              <label>
                <input type="radio" v-model="mapStyle1" value="卫星" />
                <span>卫星</span>
              </label>
            </div>
            <hr />
            <!-- <div class="clearfix">
                      <label>
                        <input type="checkbox" value="路网图" v-model="mapStyle2" />
                        <span>路网图</span>
                      </label>
                    </div> -->
            <div class="clearfix">
              <label>
                <input type="checkbox" value="地名" v-model="mapStyle2" />
                <span>地名</span>
              </label>
            </div>
            <div class="clearfix" style="margin-bottom: 0">
              <label>
                <input type="checkbox" value="虚拟护栏" v-model="mapStyle2" />
                <span>虚拟护栏</span>
              </label>
            </div>
          </div>
        </div>
        <div class="item" @click="zoomIn">
          <img src="../../assets/img/Icon/jia.png" alt />
        </div>
        <div class="item" @click="zoomOut">
          <img src="../../assets/img/Icon/jian.png" alt />
        </div>
      </div>
      <!--搜索框-->
      <div class="searchBox">
        <i-input
          v-if="searchShow"
          v-model="searchMsg"
          autosize
          prefix="ios-search"
          placeholder="搜索或输入您的地点"
          @keyup.enter.native="search"
        />
      </div>
      <!--3D地图切换图标-->
      <div
        :id="Shou3D ? 'Map3D' : ''"
        class="MapSwitch"
        ref="MapSwitch"
        v-if="MapSwitchIconShou"
        @click="Map3DSwitch"
      >
        <img src="../../assets/img/Icon/Switch3dMap.png" alt="" />
      </div>
      <!--展开条-->
      <div class="unfoldBth" ref="unfoldBth" @click="unfold">
        <!--<img src="../../assets/img/Icon/unfold.png" alt="">-->
        <!-- <i class="ivu-icon ivu-icon-ios-arrow-back"
           v-if="unfoldStatus"></i>
        <i class="ivu-icon ivu-icon-ios-arrow-forward"
           v-if="!unfoldStatus"></i> -->
        新建预案
      </div>
      <!--左方测栏-->
      <div class="leftBar" ref="leftBar" v-if="leftBarShou">
        <router-view
          :ShowMarker="ShowMarker"
          :shouAirRoute="shouAirRoute"
          :shou3DAirRoute="shou3DAirRoute"
          :close3DMap="close3DMap"
        ></router-view>
      </div>
    </div>
    <!-- 照片 -->
    <div v-show="tab === 1">
      <Spin size="large" v-if="loading" fix></Spin>
      <div class="hnmskSB">
        <span
          @click="changeTab(1)"
          style="color: #358cd6; textdecoration: underline"
          >照片</span
        ><span @click="changeTab(2)">视频</span>
      </div>
      <div v-for="(data, index) in imgdata" :key="index" class="img-list">
        <p class="media-date">
          第{{ data.flyIndex }}次执行&nbsp;&nbsp;{{
            new Date(data.img_list_point[0].createTime)
              .toLocaleString()
              .replace(/:\d{1,2}$/, ' ')
          }}
        </p>
        <ul>
          <li
            v-for="(i, idx) in data.img_list_point"
            @click="bigImg(data, idx, i.imgId)"
            :key="idx"
          >
            <div class="img-box">
              <img :src="i.imgId ? i.thumPath : i.path" loading="lazy" />
              <p>
                航点{{ idx + 1 }}&nbsp;&nbsp;{{
                  i.imgId == '0'
                    ? '未拍照'
                    : new Date(i.createTime)
                        .toLocaleString()
                        .replace(/:\d{1,2}$/, ' ')
                }}
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="imgdata.length === 0" class="noMedia">暂无图片文件</div>
    </div>
    <!-- 视频 -->
    <div v-show="tab === 2">
      <Spin size="large" v-if="loading" fix></Spin>
      <div class="hnmskSB">
        <span @click="changeTab(1)">照片</span
        ><span
          style="color: #358cd6; textdecoration: underline"
          @click="changeTab(2)"
          >视频</span
        >
      </div>
      <ul class="video-list">
        <li v-for="(i, idx) in videodata" @click="playVideo(i.path)" :key="idx">
          <img :src="i.first_frame" />
        </li>
      </ul>
      <div v-if="videodata.length === 0" class="noMedia">暂无视频文件</div>
    </div>
    <!-- 图片视频大图弹窗 -->
    <BigImgModel
      v-if="imgModal"
      :imgList="bigImgList"
      :imgTime="bigImgTime"
      :idx="bigIndex"
      :configHeight="'100vh'"
      style="position: fixed; top: 0; left: 0; width: 100%; zindex: 9999"
      @closeBigImgModel="closeImgModal()"
    ></BigImgModel>

    <!-- 视频窗口 -->
    <article v-show="videoModal">
      <Icon
        type="md-close"
        color="white"
        class="close"
        size="30"
        @click="closeVideoModal()"
      />
      <video controls ref="video" :src="bigVideo" autoplay>
        <!-- <source :src="bigVideo" type="video/mp4" />
        您的浏览器不支持Video标签。 -->
      </video>
    </article>
  </div>
</template>

<script>
import Api from '@/utils/api.js'
import { GetNotFlyArea } from '@/utils/javaApi.js'
import filterMixin from '@/utils/filter.mixin.js'
import BigImgModel from '../statistics/commonComponents/bigImgModel_path.vue'

export default {
  name: 'index',
  components: { BigImgModel },
  mixins: [filterMixin],
  data: function () {
    return {
      userType: 1,
      map: {},
      unfoldStatus: false,
      leftBarShou: true,
      searchMsg: '',
      searchShow: false,
      measureShow: false,
      stadiometryShou: true,
      mSurfaceShou: false,
      mouseTool: {},
      mapStyle1: '平面',
      mapStyle2: ['虚拟护栏'],
      hierarchyShou: false,
      satelliteLayer: {},
      roadNetLayer: {},
      infoWindow: {},
      MapSwitchIconShou: false,
      Shou3D: false,
      NFzone: [], // 虚拟护栏合集
      isAirRoute: false, // 顶部栏显示
      tab: 0, // 顶部栏切换
      teamID: '',
      page: 1,
      total: 10,
      imgdata: [],
      videodata: [],
      imgModal: false,

      bigIndex: 0,
      bigImgList: [],
      bigImgTime: '',

      videoModal: false,
      bigVideo: '',
      delID: '',
      delModel: false,
      viewer: '',
      loading: true,
    }
  },
  mounted() {
    this.userType = sessionStorage.getItem('userType')
    this.loading = false
    let layer = []
    if (this.$online) {
      layer = new AMap.TileLayer()
      //卫星图
      this.satelliteLayer = new AMap.TileLayer.Satellite()
      // 路网图
      // this.roadNetLayer = new AMap.TileLayer.RoadNet();
    } else {
      layer = new AMap.TileLayer({
        getTileUrl: function (x, y, z) {
          return Api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png'
        },
        zIndex: 10,
      })
      //卫星图
      this.satelliteLayer = new AMap.TileLayer({
        getTileUrl: function (x, y, z) {
          return Api.GaoDeSatelliteMap() + z + '/' + x + '/' + y + '.png'
        },
        zIndex: 10,
      })
    }
    //创建地图
    this.map = new AMap.Map('centerMap', {
      resizeEnable: true, //是否监控地图容器尺寸变化
      zoom: 11, //初始化地图层级
      center: this.$Center, //初始化地图中心点
      layers: [layer],
      //   layers: [
      //   AMap.TileLayer()
      //     new AMap.TileLayer({
      //       tileUrl: function (x, y, z) {
      //         return Api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png';
      //       },
      //       zIndex: 0,
      //     }),
      //   ],
    })
    // 修改主题样式
    // this.map.setMapStyle("amap://styles/whitesmoke");
    // 注册高德测量
    this.mouseTool = new AMap.MouseTool(this.map)

    //默认不显示标注
    var features = ['bg', 'road', 'building']
    this.map.setFeatures(features)

    //3D地图创建
    this.Init_map_cesium()

    // 初始化虚拟护栏
    this.initNFzone()
  },
  methods: {
    // 侧边栏伸缩动画
    unfold() {
      var width = this.$refs.leftBar.offsetWidth + 'px'
      if (this.unfoldStatus) {
        this.$refs.leftBar.style.left = `-${width}`
        this.$refs.leftBar.style['transition'] = `left 1s`
        this.$refs.unfoldBth.style.left = 0
        this.$refs.unfoldBth.style['transition'] = `left 1s`
        this.unfoldStatus = false
      } else {
        this.$refs.leftBar.style.left = 0
        this.$refs.leftBar.style['transition'] = `left 1s`
        this.$refs.unfoldBth.style.left = width
        this.$refs.unfoldBth.style['transition'] = `left 1s`
        this.unfoldStatus = true
      }
    },
    // 控件方法
    // 搜索
    searchShowFn() {
      this.searchShow = !this.searchShow
      if (this.searchShow) {
        this.$refs.searchBar.style.opacity = 1
      } else {
        this.$refs.searchBar.style.opacity = 0.6
      }
    },
    search() {
      var _this = this
      var geocoder = new AMap.Geocoder()
      var address = this.searchMsg
      geocoder.getLocation(address, function (status, result) {
        if (status === 'complete' && result.geocodes.length) {
          var lnglat = result.geocodes[0].location
          // console.log("lnglat",lnglat);
          _this.map.setCenter([lnglat.lng, lnglat.lat]) //设置地图中心点
        } else {
          _this.$Message.error('根据地址查询位置失败')
        }
      })
    },
    // 测量绘制方法
    draw(type) {
      var _this = this
      switch (type) {
        case 'rule': {
          _this.mouseTool.rule({
            startMarkerOptions: {
              //开始图标
              icon: new AMap.Icon({
                size: new AMap.Size(19, 31), //图标大小
                imageSize: new AMap.Size(19, 31),
                image: require('@/assets/img/Marker/start.png'),
              }),
            },
            endMarkerOptions: {
              //结束图标
              icon: new AMap.Icon({
                size: new AMap.Size(19, 31), //图标大小
                imageSize: new AMap.Size(19, 31),
                image: require('@/assets/img/Marker/end.png'),
              }),
              offset: new AMap.Pixel(-9, -31),
            },
            midMarkerOptions: {
              //中间点图标
              icon: new AMap.Icon({
                size: new AMap.Size(19, 31), //图标大小
                imageSize: new AMap.Size(19, 31),
                image: require('@/assets/img/Marker/mid.png'),
              }),
              offset: new AMap.Pixel(-9, -31),
            },
            lineOptions: {
              //线的样式
              strokeStyle: 'solid',
              strokeColor: '#FF33FF',
              strokeOpacity: 1,
              strokeWeight: 2,
            },
            //同 RangingTool 的 自定义 设置，缺省为默认样式
          })
          break
        }
        case 'measureArea': {
          _this.mouseTool.measureArea({
            strokeColor: '#80d8ff',
            fillColor: '#80d8ff',
            fillOpacity: 0.3,
            //同 Polygon 的 Option 设置
          })
          break
        }
      }
    },
    // 测量
    measureFn() {
      this.measureShow = !this.measureShow
      this.mouseTool.close(true) //关闭，并清除覆盖物

      if (this.measureShow) {
        this.$refs.measureBar.style.opacity = 1
        this.draw('rule') //默认加载测距
      } else {
        this.$refs.measureBar.style.opacity = 0.6
        this.mouseTool.close(true) //关闭，并清除覆盖物
        this.stadiometryShou = true
        this.mSurfaceShou = false
      }
      // 未完待续====================
    },
    // 测距
    stadiometryFn() {
      this.stadiometryShou = !this.stadiometryShou
      this.mSurfaceShou = false
      this.$refs.mSurface.style.opacity = 0.6
      this.mouseTool.close(true) //关闭，并清除覆盖物
      if (this.stadiometryShou) {
        this.$refs.stadiometry.style.opacity = 1
        this.draw('rule') //开启测距
      } else {
        this.$refs.stadiometry.style.opacity = 0.6
      }
    },
    // 测面积
    mSurfaceFn() {
      this.mSurfaceShou = !this.mSurfaceShou
      this.stadiometryShou = false
      this.$refs.stadiometry.style.opacity = 0.6
      this.mouseTool.close(true) //关闭，并清除覆盖物
      if (this.mSurfaceShou) {
        this.$refs.mSurface.style.opacity = 1
        this.draw('measureArea') //开启测面
      } else {
        this.$refs.mSurface.style.opacity = 0.6
      }
    },
    // 开关地图类型勾选列表
    hierarchyFn() {
      this.hierarchyShou = !this.hierarchyShou
      console.log(this.hierarchyShou)
      if (this.hierarchyShou) {
        this.$refs.hierarchyBar.style.opacity = 1
      } else {
        this.$refs.hierarchyBar.style.opacity = 0.6
      }
    },
    // 放大
    zoomIn() {
      this.map.zoomIn()
    },
    // 缩小
    zoomOut() {
      this.map.zoomOut()
    },
    // 显示地图上的任务信息
    ShowMarker(listData, ProjectType) {
      this.infoWindow = new AMap.InfoWindow({ offset: new AMap.Pixel(0, -30) })
      console.log('ProjectType', ProjectType)
      //   this.initNFzone();
      this.map.clearMap()
      //   this.map.add(this.NFzone);
      //创建图标,绑定事件
      if (ProjectType == 3) {
        //当是显示任务列表
        listData.forEach((item) => {
          var iconImg
          let firstlocal
          if (item.tasktype == 'airroute') {
            iconImg = require('../../assets/img/Marker/line_green.png')
            firstlocal = item.waypoint[0]
          } else if (item.tasktype == 'figure') {
            iconImg = require('../../assets/img/Marker/pic_red.png')
            firstlocal = item.local_data[0]
          }
          if (firstlocal) {
            let marker = new AMap.Marker({
              icon: new AMap.Icon({
                image: iconImg,
                size: new AMap.Size(25, 40),
              }),
              offset: new AMap.Pixel(-15, -40),
              position: [
                Number(eval(firstlocal).lng),
                Number(eval(firstlocal).lat),
              ],
            })
            marker.setLabel({
              offset: new AMap.Pixel(-50, -28),
              content: `<div class="mark_label"><P>${item.filetitle}</P></div>`,
            })
            marker.content = `<div class="marker-content" id=#${item.pm_id}>
                    <ul>
                        <li><i class="ivu-icon ivu-icon-ios-home-outline"></i>${item.filetitle}</li>
                        <li><i class="ivu-icon ivu-icon-ios-time-outline"></i>${item.data_create_time}</li>
                        <li><i class="ivu-icon ivu-icon-md-person"></i>${item.name}</li>
                        <li><i class="ivu-icon ivu-icon-md-log-in"></i>点击进入任务</li>
                    </ul>
                </div>
                  `
            marker.data = item
            marker.id = item.pm_id
            this.map.add(marker)
            marker.on('mouseover', this.shouInfoWindow)
          }
        })
        if (listData.length == 0) {
          this.map.setZoomAndCenter(11, this.$Center)
        } else {
          this.map.setFitView()
        }
        setTimeout(() => {
          this.map.add(this.NFzone)
        }, 500)
      } else {
        if (ProjectType == 1) {
          var iconImg = require('../../assets/img/Marker/Level_1_project.png')
        } else if (ProjectType == 2) {
          var iconImg = require('../../assets/img/Marker/Level_2_project.png')
        }

        listData.forEach((item) => {
          let marker = new AMap.Marker({
            icon: new AMap.Icon({
              image: iconImg,
              size: new AMap.Size(25, 40),
            }),
            offset: new AMap.Pixel(-15, -40),
            position: [
              Number(eval(item.location)[0].lng),
              Number(eval(item.location)[0].lat),
            ],
          })
          marker.setLabel({
            offset: new AMap.Pixel(-50, -28),
            content: `<div class="mark_label"><P>${item.pm_name}</P></div>`,
          })
          marker.content = `<div class="marker-content" id=#${item.pm_id}>
                    <ul>
                        <li><i class="ivu-icon ivu-icon-ios-home-outline"></i>${item.pm_name}</li>
                        <li><i class="ivu-icon ivu-icon-ios-time-outline"></i>${item.data_create_time}</li>
                        <li><i class="ivu-icon ivu-icon-md-person"></i>${item.pm_administrator}</li>
                        <li><i class="ivu-icon ivu-icon-md-log-in"></i>点击进入项目</li>
                    </ul>
                </div>
                  `
          marker.data = item
          marker.id = item.pm_id
          this.map.add(marker)
          marker.on('mouseover', this.shouInfoWindow)
        })
        if (ProjectType == 2) {
          this.map.setFitView()
          setTimeout(() => {
            this.map.add(this.NFzone)
          }, 500)
        }
      }
    },
    // 移入显示详细信息
    shouInfoWindow(e) {
      var _this = this
      this.infoWindow.setContent(e.target.content)
      this.infoWindow.open(this.map, e.target.getPosition())
      // 点击信息进入工程,任务
      setTimeout(function () {
        var a = document.getElementById(`#${e.target.id}`)
        a.onclick = function () {
          _this.IntoPproject(e.target.data)
        }
      }, 80)
    },
    //进入任务
    IntoPproject(e) {
      console.log('//进入工程,任务', e, this.$route.name)
      console.log('进入任务', e)
      if (e.tasktype == 'airroute') {
        //点击航线
        this.shouAirRoute(e, '航线')
      } else if (e.tasktype == 'figure') {
        //点击建图
        this.shouAirRoute(e, '建图')
      }
      this.IntoheTask(e)

      // if (this.$route.name == "一级工程列表详情") {
      //     console.log("进入一级工程,展示二级工程列表");
      //     this.$_bus.$emit("Into_1_project", e);
      // } else if (this.$route.name == "二级工程列表详情") {
      //     console.log("进入二级工程,展示任务列表");
      //     this.$_bus.$emit("Into_2_project", e);
      // } else if (this.$route.name == "任务列表详情") {
      //     console.log("进入任务", pm_id, e);
      //     if (e.tasktype == "airroute") {
      //         //点击航线
      //         this.shouAirRoute(e, "航线");
      //     } else if (e.tasktype == "figure") {
      //         //点击建图
      //         this.shouAirRoute(e, "建图");
      //     }
      //     this.IntoheTask(e);
      // }
    },
    // 点击列表进入任务
    IntoheTask(item) {
      var _this = this
      // console.log("点击列表进入任务",item);
      sessionStorage.setItem('getTaskData', JSON.stringify(item))
      this.$router.push({
        path: '/index/project/TaskBar',
      })
    },
    // 显示航线或建图
    shouAirRoute(Data, Type) {
      console.log('显示航线', Data, Type)
      let _this = this
      //   this.initNFzone();

      this.map.clearMap()
      this.map.add(this.NFzone)
      if (Type == '航线') {
        this.isAirRoute = true
        this.MapSwitchIconShou = true
        let waypoint = Data.waypoint
        var path = []
        waypoint.forEach((item) => {
          path.push([item.lng, item.lat, item.altitude])
        })

        //画点
        path.forEach((item, ind) => {
          let simpleMarker = new AMap.Marker({
            position: [item[0], item[1]], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            icon: new AMap.Icon({
              size: new AMap.Size(60, 60), // 图标尺寸
              image: require('../../assets/img/Marker/MediaImg.png'), // Icon的图像
              imageSize: new AMap.Size(60, 60), // 根据所设置的大小拉伸或压缩图片
            }),
            label: {
              //   offset: new AMap.Pixel(-30, -15), //设置文本标注偏移量--设置后位置错误
              content: `<div style="line-height: 20px;">航点${ind + 1},高度${
                item[2]
              }m</div>`, //设置文本标注内容
              direction: 'top', //设置文本标注方位
            },
            offset: new AMap.Pixel(-30, -30),
            map: _this.map,
          })
          simpleMarker.ind = ind
          simpleMarker.DATA = Data
          simpleMarker.on('click', _this.shouPicture)
        })

        //画线
        var polyline = new AMap.Polyline({
          path: path,
          isOutline: true,
          outlineColor: '#fff',
          borderWeight: 0,
          strokeColor: '#4C98FF',
          strokeOpacity: 1,
          strokeWeight: 6,
          strokeStyle: 'solid',
          strokeDasharray: [10, 5],
          lineJoin: 'round',
          lineCap: 'round',
          zIndex: 50,
        })

        polyline.setMap(_this.map)
        // 缩放地图到合适的视野级别
        _this.map.setFitView([polyline])
      } else if (Type == '建图') {
        let local_data = Data.local_data
        var path = []
        local_data.forEach((item) => {
          path.push([item.lng, item.lat])
        })
        //画点
        path.forEach((item) => {
          let marker = new AMap.Marker({
            position: item,
          })
          marker.DATA = Data
          this.map.add(marker)
          //   marker.on('click', this.shouOrthophotoMap);
        })

        // 画多边形
        var polygon = new AMap.Polygon({
          path: path,
          strokeColor: '#FF33FF',
          strokeWeight: 6,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          fillColor: '#1791fc',
          zIndex: 50,
        })
        polygon.DATA = Data
        this.map.add(polygon)
        // 缩放地图到合适的视野级别
        this.map.setFitView([polygon])
        // polygon.on('click', this.shouOrthophotoMap);
      }
    },
    // 点击了航点 查看照片
    shouPicture(e) {
      this.$_bus.$emit('Into_Picture', e.target)
    },
    // 创建Cesium地图
    Init_map_cesium() {
      let that = this
      // 使用的Cesium提供的地图和地形数据，就必须申请账号和创建AccessToken
      Cesium.Ion.defaultAccessToken = Api.CesiumToken()
      //镜头默认范围(必须在viewer实例创建前设置)
      // 定位在中国上空
      Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
        73.0,
        3.0,
        135.0,
        53.0
      )

      that.viewer = new Cesium.Viewer('center3DMap', {
        animation: false, //是否创建动画小器件，左下角仪表
        baseLayerPicker: false, //是否显示图层选择器
        fullscreenButton: false, //是否显示全屏按钮
        geocoder: false, //是否显示geocoder小器件，右上角查询按钮
        homeButton: true, //是否显示Home按钮
        infoBox: false, //是否显示信息框
        sceneModePicker: false, //是否显示3D/2D选择器
        selectionIndicator: false, //是否显示选取指示器组件
        timeline: false, //是否显示时间轴
        navigationHelpButton: false, //是否显示右上角的帮助按钮
        // // 加载Cesium自带地图
        // imageryProvider: new Cesium.TileMapServiceImageryProvider({
        //   url: Cesium.buildModuleUrl('Assets/Textures/NaturalEarthII'),
        // }),
      })
      that.viewer.scene.globe.show = false //不显示地球，这条和地球透明度选一个就可以
      // 全局注册cesium(不能挂载到data里,数据劫持会造成地图卡顿)
      //   window.Viewer_airroute = viewer;
      // 去掉版权信息
      that.viewer.cesiumWidget.creditContainer.style.display = 'none'

      //天地图卫星影像
      // 加载本地地图=======================================
      //   var arcgis_blue = that.viewer.imageryLayers.addImageryProvider(
      //     new Cesium.TileMapServiceImageryProvider({
      //       // 有的版本是用createTileMapServiceImageryProvider
      //       url: Api.TianDiTuMap(),
      //       fileExtension: 'png',
      //     })
      //   );

      // 开启地形深度检测
      that.viewer.scene.globe.depthTestAgainstTerrain = true

      //加载地形
      // viewer.terrainProvider = Cesium.createWorldTerrain();

      // home定位到具体地点和高度(长沙)
      that.viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
        function (e) {
          e.cancel = true
          //你要飞的位置
          that.viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(
              that.$Center[0],
              that.$Center[1],
              //   119.286808,
              //   26.053644,
              50000
            ),
          })
        }
      )

      //阻止摄像头到地下(还有缺陷)
      // viewer.clock.onTick.addEventListener(function () {
      //   if (viewer.camera.pitch > 0) {
      //     viewer.scene.screenSpaceCameraController.enableTilt = false;
      //   }
      // });
      //
      // var mousePosition, startMousePosition;
      // var handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      // handler.setInputAction(function (movement) {
      //   mousePosition = startMousePosition = Cesium.Cartesian3.clone(movement.position);
      //   handler.setInputAction(function (movement) {
      //     mousePosition = movement.endPosition;
      //     var y = mousePosition.y - startMousePosition.y;
      //     if (y > 0) {
      //       viewer.scene.screenSpaceCameraController.enableTilt = true;
      //     }
      //   }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      // }, Cesium.ScreenSpaceEventType.RIGHT_DOWN);
    },
    // 显示3D航线或建图
    shou3DAirRoute(Data, Type) {
      let _this = this
      let viewer = this.viewer
      viewer.entities.removeAll()

      if (Type == '航线') {
        let waypoint = Data.waypoint
        var path = []
        waypoint.forEach((item) => {
          path.push([item.lng, item.lat, item.altitude])
        })

        // home定位到项目地点和高度
        // 高德坐标转WGS84
        let Location = gcj02towgs84(path[0][0], path[0][1])
        // console.log("Locatio",Location);
        viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
          function (e) {
            e.cancel = true
            //你要飞的位置
            viewer.camera.flyTo({
              destination: Cesium.Cartesian3.fromDegrees(
                Location[0],
                Location[1],
                1000
              ),
            })
          }
        )

        let WGS84_path = []

        // 添加项目图标
        path.forEach((item, ind) => {
          // 高德坐标转WGS84
          let markerLocation = gcj02towgs84(item[0], item[1])
          let lng = markerLocation[0]
          let lat = markerLocation[1]
          WGS84_path.push(markerLocation)

          viewer.entities.add({
            // id:Data.id,
            name: '航点',
            position: Cesium.Cartesian3.fromDegrees(lng, lat, item[2]),
            // polyline : {// 画线
            //   positions : Cesium.Cartesian3.fromDegreesArrayHeights(
            //           [ lng,lat,0, lng,lat,item[2]]
            //   ),
            //   width : 1,
            //   material : new Cesium.PolylineOutlineMaterialProperty({
            //     color : Cesium.Color.RED,
            //     outlineWidth : 0,
            //     // outlineColor : Cesium.Color.BLACK
            //   })
            // },
            // billboard : { //图标
            //   image : require('../../assets/img/Marker/uav.png')
            // },
            model: {
              // 模型路径
              uri: require('@/assets/glb/aaa.glb'),
              // 模型最小刻度
              // minimumPixelSize: 128,
              // 模型最大刻度
              // maximumSize: 128,
              //设置模型最大放大大小
              // maximumScale : 200,
              // 模型是否可见
              show: true,
              // 模型轮廓颜色
              // silhouetteColor:Cesium.Color.WHITE,
              // 模型颜色  ，这里可以设置颜色的变化
              // color: Cesium.Color.LIME ,
              // 仅用于调试，显示魔仙绘制时的线框
              debugWireframe: false,
              // 仅用于调试。显示模型绘制时的边界球。
              debugShowBoundingVolume: false,
              scale: 50,
              //是否运行模型中的动画效果
              runAnimations: false,
            },
            label: {
              //定义标签title
              text: '航点 : ' + (ind + 1),
              font: 'normal 14px MicroSoft YaHei',
              scale: 0.8,
              style: Cesium.LabelStyle.FILL_AND_OUTLINE,
              color: Cesium.Color.WHITE,
              showBackground: true,
              horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              pixelOffset: new Cesium.Cartesian2(0, -30),
            },
            DATA: Data,
            ind: waypoint[ind].order,
          })
        })
        waypoint.forEach((item, ind) => {
          WGS84_path[ind].push(Number(item.altitude))
        })
        let positions = []
        WGS84_path.forEach((item) => {
          positions.push(item[0], item[1], item[2])
        })
        // console.log("画线的路径",positions);
        // 画线
        viewer.entities.add({
          // id:item.pm_id,
          name: '航线',
          polyline: {
            // 画线
            positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
            width: 2,
            material: new Cesium.PolylineOutlineMaterialProperty({
              color: new Cesium.Color(47 / 255, 204 / 255, 113 / 255, 1),
              outlineWidth: 0,
              // outlineColor : Cesium.Color.BLACK
            }),
          },
        })
        viewer.zoomTo(viewer.entities)

        //自定义单击事件
        viewer.screenSpaceEventHandler.setInputAction(function (click) {
          // 处理鼠标按下事件，获取鼠标当前位置
          // console.log(click);
          var feature = viewer.scene.pick(click.position)
          //选中某模型
          // console.log("feature.DATA",feature);
          if (feature && feature.id.DATA) {
            _this.shouPicture_3D(feature.id)
          }
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK)
      } else if (Type == '建图') {
      }
    },
    // 点击了3D航点 查看照片
    shouPicture_3D(data) {
      this.$_bus.$emit('Into_Picture', data)
    },
    // 切换地图
    Map3DSwitch() {
      this.Shou3D = !this.Shou3D
      if (this.Shou3D) {
      }
    },
    //隐藏3D地图
    close3DMap() {
      this.Shou3D = false
      this.MapSwitchIconShou = false
    },
    //显示正射地图-------------------5/29暂时取消功能,在建图任务查看航线区域时要求展示按时间分类的模型或正射
    // shouOrthophotoMap(e) {
    //   this.$_bus.$emit('Into_OrthophotoMap', e.target);
    // },

    // 初始化虚拟护栏
    initNFzone() {
      let that = this
      this.map.clearMap()
      if (this.NFzone.length > 0) {
        that.map.remove(that.NFzone)
        this.NFzone = []
      }

      this.$post(GetNotFlyArea(), {
        team_id: JSON.parse(sessionStorage.getItem('user_info')).team_id,
        showTeam: 0,
      }).then((res) => {
        if (res.code === 1) {
          res.data.forEach((element) => {
            let path = []
            element.local_data.forEach((ele) => {
              path.push(
                // new AMap.LngLat(
                //   window.gcj02towgs84(ele.lng,ele.lat)[0],
                //   window.gcj02towgs84(ele.lng, ele.lat)[1]
                // )
                new AMap.LngLat(ele.lng, ele.lat)
              )
            })

            let polygon = new AMap.Polygon({
              path: path,
              fillColor: '#c41a16',
              strokeColor: '#ff0000',
              fillOpacity: 0.3,
            })
            that.NFzone.push(polygon)
          })
          that.map.add(that.NFzone)
        }
      })
    },

    /**
     * 航点图片视频展示
     */
    changeTab(n) {
      this.tab = n
      let lis = document.querySelectorAll('.tabs>li')
      this.imgdata = []
      this.videodata = []
      lis.forEach((e) => {
        e.setAttribute('class', '')
      })
      if (n != 0) {
        lis[1].setAttribute('class', 'active')
      } else {
        lis[0].setAttribute('class', 'active')
      }
      if (this.tab !== 0) {
        this.loading = true
        this.$post(Api.airRouteMedia(), {
          client: 'web',
          air_route_id: JSON.parse(sessionStorage.getItem('getTaskData')).id,
        }).then((res) => {
          if (res.code == 1) {
            if (res.data.img_list) {
              this.imgdata = res.data.img_list.reverse()
            }
            if (res.data.video_list) {
              this.videodata = res.data.video_list
            }
          }
          this.loading = false
        })
      }
    },
    // 图片视频大图查看
    /**
     * 照片
     */
    // 查看大图
    bigImg(m, idx, imgId) {
      if (imgId) {
        //   let n = [];
        //   m.img_list_point.forEach((e) => {
        //     n.push(e.path);
        //   });
        this.bigIndex = idx
        this.bigImgList = m.img_list_point
        this.bigImgTime = m.date
        this.imgModal = true
      }
    },

    // 关闭弹窗
    closeImgModal() {
      //   document.documentElement.style.overflowY = "auto";
      this.imgModal = false
      this.bigIndex = 0
      this.bigImgList = []
      this.bigImgTime = ''
    },
    /**
     * 视频
     */
    playVideo(path) {
      document.documentElement.style.overflowY = 'hidden'
      this.bigVideo = path
      this.videoModal = true
    },
    closeVideoModal() {
      document.documentElement.style.overflowY = 'auto'
      this.$refs.video.pause()
      this.bigVideo = ''
      this.videoModal = false
    },
  },
  watch: {
    $route(to, from) {
      if (to.path == '/index/project/TaskBar') {
        console.log('当前路由是航线页面')
        // this.MapSwitchIconShou=true
      } else {
        this.tab = 0
        this.isAirRoute = false
        this.MapSwitchIconShou = false
      }
    },
    mapStyle1(val) {
      //卫星平面切换
      if (val == '平面') {
        this.map.remove(this.satelliteLayer)
      } else if (val == '卫星') {
        this.map.add(this.satelliteLayer)
      }
    },
    mapStyle2(val) {
      //路网,虚拟护栏显示
      // 路网
      // if (val.indexOf("路网图") !== -1) {
      //     this.map.add(this.roadNetLayer);
      // } else {
      //     this.map.remove(this.roadNetLayer);
      // }
      // 虚拟护栏
      if (val.indexOf('虚拟护栏') !== -1) {
        this.NFzone.forEach((element) => {
          element.show()
        })
      } else {
        this.NFzone.forEach((element) => {
          element.hide()
        })
      }
      //显示默认标注
      if (val.indexOf('地名') !== -1) {
        let features = ['bg', 'road', 'building', 'point']
        this.map.setFeatures(features)
      } else {
        let features = ['bg', 'road', 'building']
        this.map.setFeatures(features)
      }
    },
  },
  beforeDestroy() {
    if (this.viewer != '') {
      this.viewer.destroy()
      this.viewer = ''
    }
  },
}
</script>

<style scoped lang="scss">
header {
  background-color: #464d54;
  color: #fff;
  position: relative;
  width: 100%;
  z-index: 2;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .tabs {
    display: inline-flex;
    li {
      padding: 0 16px;
      border-radius: 18px;
      cursor: pointer;
      height: 29px;
      line-height: 29px;
    }
    .active {
      background-color: #fff;
      color: #464d54;
    }
  }
}
#project {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #333;
  .centerMap {
    width: 100%;
    height: 100%;
  }

  .center3DMap {
    width: 100%;
    height: 900px;
  }

  /deep/ .amap-marker-label {
    //修改高德默认label样式
    background-color: #fff;
    text-align: center;
    width: 120px;
    height: 25px;
    border-radius: 4px;
    border: 1px solid #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    overflow: hidden;
    padding: 0 4px;

    .mark_label {
      p {
        line-height: 25px;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  /deep/ .amap-info-contentContainer {
    .amap-info-content {
      border-radius: 10px;
      padding: 15px 35px 15px 25px;

      .marker-content {
        li {
          line-height: 20px;

          i {
            margin-right: 6px;
            font-size: 16px;
          }
        }
      }
    }

    .amap-info-close {
      right: 10px !important;
      top: 10px !important;
    }
  }

  .mapControl {
    width: 50px;
    max-height: 400px;
    position: absolute;
    bottom: 40px;
    right: 10px;

    .item {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #111;
      opacity: 0.6;
      cursor: pointer;
      margin-bottom: 16px;
      position: relative;

      img {
        width: 20px;
      }

      .measureBox {
        position: absolute;
        top: 0;
        left: -120px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
          padding: 3px 10px;
          background-color: #333;
          opacity: 0.6;
          border-radius: 2px;
          margin-right: 10px;
          span {
            font-size: 16px;
            color: #fff;
          }
        }

        :nth-child(1) {
          opacity: 1;
        }
      }

      .hierarchyBox {
        z-index: 1000 !important;
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: -90px;
        background-color: #333;
        padding: 10px;

        > div {
          margin-bottom: 6px;

          input {
            float: left;
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }

          span {
            float: right;
            font-size: 12px;
            color: #fff;
            line-height: 16px;
          }
        }

        hr {
          margin: 15px 0;
        }
      }
    }

    .item:hover {
      opacity: 1;
    }
  }

  .MapSwitch {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: #333;
    opacity: 0.5;
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 50px;

    img {
      width: 80%;
      height: auto;
    }
  }

  #Map3D {
    opacity: 1;
  }

  .unfoldBth {
    width: 20px;
    height: 120px;
    background-color: #4c98ff;
    border-radius: 0px 10px 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0px;
    padding: 0 10px;
    cursor: pointer;
    @include ct;

    img {
      width: 14px;
      height: 24px;
    }

    i {
      font-size: 25px;
      color: #fff;
    }
  }

  .searchBox {
    @include cl;
    top: 30px;
    width: 500px;
    height: 50px;

    /deep/ .ivu-input {
      height: 40px;
      border-radius: 50px;
      border: none;
      padding-left: 55px;
      box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
    }

    /deep/ .ivu-input:focus {
      outline: none;
      box-shadow: 0 0 0 2px transparent;
      border: 1px solid #409eff;
    }

    /deep/ .ivu-input-prefix,
    .ivu-input-suffix {
      width: 60px;
      height: 40px;
      line-height: 40px;

      i {
        font-size: 25px;
        line-height: 40px;
      }
    }
  }

  .leftBar {
    width: 340px;
    height: 100%;
    background-color: #fafafa;
    position: absolute;
    top: 0;
    left: -340px;
    z-index: 700 !important;
  }

  .leftBar::-webkit-scrollbar {
    display: none;
  }
}
.noMedia {
  font-size: 16px;
  text-align: center;
  margin: 50px 0;
}
.hnmskSB {
  margin: 10px 0;
  span {
    display: inline-block;
    margin: 0 60px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
  }
}
// 图片
.img-list {
  margin: 20px 0 15px 28px;
  p {
    font-size: 12px;
    margin-bottom: 10px;
  }
  ul {
    li {
      display: inline-block;
      width: 230px;
      //   height: 230px;
      overflow: hidden;
      margin-right: 25px;
      margin-bottom: 15px;
      cursor: pointer;
      position: relative;
      img {
        object-fit: cover;
        width: 100%;
        height: 230px;
      }
      p {
        text-align: center;
      }
    }
  }
}
// 视频
.video-list {
  margin: 20px 0 15px 28px;
  p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  li {
    display: inline-block;
    width: 200px;
    height: 200px;
    overflow: hidden;
    margin-right: 25px;
    cursor: pointer;
    position: relative;
    img {
      height: 200px;
      margin: 0 auto;
    }
  }
}

.media-date {
  font-size: 16px;
  display: inline-block;
  padding: 0 10px;
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
  color: #fff;
  background-color: #595a5c;
}

// 弹窗
aside {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(23, 23, 23, 0.84);
  .img-modal-time {
    color: #fff;
    font-size: 16px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
  .big-img-box {
    width: 90%;
    height: 800px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
  }
  .arrow {
    position: absolute;
    top: 51%;
    width: 40px;
    height: 40px;
    line-height: 45px;
    text-align: center;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
  }
  .big-list-box {
    width: 100%;
    height: 100px;
    background-color: #000;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      li {
        width: 62px;
        height: 62px;
        overflow: hidden;
        margin: 15px 5px 0;
        border: 4px solid #000;
        box-sizing: content-box;
        img {
          height: 62px;
          margin: 0 auto;
        }
      }
      .active {
        border: 4px solid #4c98ff;
      }
    }
  }
}
article {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(23, 23, 23, 0.84);
  display: flex;
  justify-content: center;
  align-items: center;
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    z-index: 99;
    background-color: #000;
    border-radius: 10px;
  }
  video {
    max-height: 100%;
    max-width: 100%;
  }
}
</style>
